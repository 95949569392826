@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Press+Start+2P&display=swap');

.notfound__container {
  display: flex;
  justify-content: center;
  margin: auto;
}

.landing-page__content {
  height: 400px;
  width: 400px;
  /* border: 2px solid #28C4FC; */
  background: #ffffff;
  /* box-shadow: 1px 2px 12px 1px #28c4fc;*/
}

.lading-page__main {
  margin-top: 100px;
}

.landing-page__customers,
.landing-page__employee {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 400px;
  border-radius: 0.5rem;
  flex-grow: 0.2;
  padding: 2rem;
  background-color: rgba(223, 220, 220, 0.4);
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(255, 104, 44, 0.22) 0px 15px 12px;
}

.landing-page__customer-title,
.landing-page__employee-title {
  color: #4e564e;
  font-family: 'Open Sans Condensed', sans-serif;
}

.landing-page__cus-button,
.landing-page__employee-button {
  display: flex;
  justify-content: center;
}

.landing-page__btn {
  color: #ff682c !important;
  background-color: #f4f9e9 !important;
}
