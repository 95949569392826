.home__stats-container {
  margin-left: 20px;
}

.stats__rvp-container-1 {
  border: #28c4fc solid 2px;
}

.stats__line-graph.stats__line-graph {
  height: 900px !important;
}

.stats__card-item {
  min-width: 346px;
}

.stats__icon {
  font-size: 3rem !important;
  color: rgba(251, 198, 88, 0.8) !important;
}

.stats__card-company {
  font-size: 12px !important;
}

.stats__card-style-name {
  text-transform: capitalize;
}

.status__card-calendar-icon {
  font-size: 1rem !important;
}

.stats_card-date {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.status__card-last-seen {
  font-size: 11px !important;
}

.stats__card-data * {
  margin-bottom: 0 !important;
}

.stats__icon_searched {
  color: #6bd098 !important;
  font-size: 3rem !important;
}

.stats__card-company-viewed {
  color: transparent !important;
}

.stats__icon-searched-company {
  color: #ef8157 !important;
  font-size: 3rem !important;
}

.stats__icon-most-favorite {
  font-size: 3rem !important;
  color: #ea3323 !important;
}

.stats__icon-most-checkedout {
  font-size: 3rem !important;
  color: #009d47 !important;
}

.status__card-secondary-icon {
  color: rgba(0, 0, 0, 0.5) !important;
}

.stats__mvp-container {
  padding-top: 20px;
}

.stats__mvp-header {
  padding: 15px;
  font-weight: 300 !important;
  background-color: #142e3e;
  color: #28c4fc;
  border-radius: 3px;
}

.stats__mvp-pie,
.stats__mvp-table {
  padding: 15px;
  width: 50%;
}

.stats__mvp-table-mvp {
  width: 65%;
}

.stats__mvp-pie-mvp {
  width: 35%;
}

.stats__mvp-stats {
  display: flex;
  flex-direction: row;
}

/* .stats__mvp-table-container {
  max-height: 480px;
} */

.stats__search-button.stats__search-button {
  margin-top: 27px;
  color: #ff682c !important;
}

@media only screen and (max-width: 850px) {
  .stats__mvp-table,
  .stats__mvp-pie {
    width: 100%;
  }
  .stats__mvp-stats {
    display: flex;
    flex-direction: column;
  }
}

.stats__mvp-data-download-container {
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff !important;
}

.stats__mvp-data-download-button {
  margin: 10px !important;
  background-color: transparent !important;
}

.stats__grid-container-cards {
  justify-content: center;
}

.stats__rvp-container,
.stats__mvm-container {
  margin-top: 20px;
}
