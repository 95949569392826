.fav-header-text {
  float: left !important;
}

.favorites__container-full {
  min-width: 100%;
  min-height: 500px;
}

.favorites__container-mobile {
  min-width: 100%;
  min-height: 500px;
  display: none;
}

.favorites__header {
  text-align: center !important;
  background-color: #142e3e !important;
  color: #28c4fc !important;
}

.favorites__scroll-menu {
  background-color: #dcdcdc !important;
  color: #4e564e !important;
}

.favorites__tabs {
  color: #4e564e !important;
}

.favorites__menu-icon {
  color: #142e3e !important;
  margin-right: 20px;
}

.favorite__links {
  cursor: pointer;
  color: #4e564e;
}
@media only screen and (max-width: 760px) {
  .favorites__header {
    text-align: center !important;
    background-color: #142e3e !important;
    color: #28c4fc !important;
  }

  .favorites__container-full {
    min-width: 100%;
    min-height: 500px;
    display: none;
  }

  .favorites__container-mobile {
    min-width: 100%;
    min-height: 0px;
    display: block;
  }

  .favorites__list-menu {
    margin-top: -10px !important;
    background-color: #142e3e !important;
    color: #28c4fc !important;
    border-radius: 3px !important;
    height: 90px;
  }

  .favorites__list-sub-header {
    font-size: 15px !important;
    color: #4e564e;
  }
}
