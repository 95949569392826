/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;500&display=swap');
/* 
.print_queue_menu_divider {

} */

.product__mobile--view {
  word-wrap: break-word;
}

.product__pending-dialog-header {
  color: #28c4fc !important;
}

.product__pending-header-button {
  color: #ff682c !important;
  font-size: 20px !important;
  margin-left: 70px !important;
  float: right !important;
  border: 2px #28c4fc solid !important;
}

/* .product__pending-header-chip {
  font-size: 0px !important;
  float: left !important;
} */

.product__icon-buttons {
  border-radius: 40px !important;
}

.product__icon-container {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.product__like-icon {
  margin-right: 40px;
}

.product__checkout_icon {
  margin-left: 0px;
}

.price-text {
  text-decoration-line: line-through;
  font-size: 20pt !important;
  font-weight: 500 !important;
}

.price-text-default {
  font-style: italic;
  font-size: 20pt !important;
  font-weight: 500 !important;
}

.footer {
  text-align: center;
  font-style: italic;
}

.product-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 30em;
}

.product-info table tbody tr td {
  padding: 10px 20px 10px 20px;
}

.product-info table tbody tr td:last-child {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 600;
  width: 50px;
}

.product__price-tbody {
  text-align: center;
}

.qrcode {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.print-button {
  margin: 10px 0px;
  display: flex;
  justify-content: center;
}

.notes {
  display: flex;
  justify-content: center;
}

.product-notes {
  color: rgba(0, 0, 0, 0.6);
}

.product-editor.product-editor {
  width: 200px;
  /* background-color: #f4f9e9 !important; */
  color: #ff682c;
}

.product__size-selector {
  min-width: 120px;
  max-width: 300px;
}

.product__price {
  width: auto;
  padding: 0px;
}

.product__price-container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: auto;
  width: 90%;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.product__view-details,
.product__view-price {
  background-color: rgba(255, 255, 255, 0.45) !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px !important;
  padding-top: 20px;
}

.product_view-details-table {
  padding-top: 25px;
  padding-bottom: 25px;
  width: 100px;
}

.product__view-price-table {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.product__view-price-table td {
  text-align: center;
}

.product__price-currency {
  position: relative;
  top: 28px;
  left: -45px;
}

.product__price-uom {
  position: relative;
  bottom: 24px;
  left: 80px;
}

.container {
  object-fit: none;
  margin: auto;
  padding: 0px;
}

#product-desc-table:nth-child(even) {
  background-color: #c9c9c9c9;
}

#options_dd {
  max-width: 100%;
}

.product-disclaimer {
  font-weight: 100 !important;
  font-family: sans !important;
  text-align: center;
  padding-bottom: 20px;
}

.product__view-options {
  background-color: rgba(255, 255, 255, 0.45);
  width: 100%;
}

.product__options {
  white-space: normal !important;
}

.product__table-grid {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 0px;
}

.product__table-container {
  display: initial;
}

.product__list-container {
  display: none;
}

@media only screen and (max-width: 975px) {
  .product__table-grid {
    border-left: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .product__table-container {
    display: none;
  }

  .product__list-container {
    display: initial;
  }

  .product__list-container p {
    color: unset !important;
  }
}

/* @media only screen and (max-width: 800px) {
    .container {
      object-fit: none;
      margin: auto;
      width: 30em !important;
    }
  } */

@media only screen and (max-width: 700px) {

  .product__pending-dialog-header {
    color: #28c4fc !important;
    font-size: 30px;
  }

  .product__pending-header-button {
    color: #ff682c !important;
    font-size: 20px !important;
    margin-left: 70px !important;
    float: right !important;
    border: 2px #28c4fc solid !important;
  }

  .product__pending-header-chip {
    font-size: 9px !important;
    float: right !important;
  }

  .product__price-container {
    width: 90%;
    margin: auto;
  }

  .product__details-container {
    width: auto;
  }
}

@media only screen and (max-width: 650px) {
  .container {
    width: 20em !important;
    padding: 0px;
  }

  .product__view-details,
  .product__view-price {
    background-color: #dedcdc !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px !important;
  }

  .product__price-container {
    width: 90%;
    margin: auto;
  }

  .product__details-container {
    width: 500px !important;
  }

  .product {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 0em;
  }
}

@media only screen and (max-width: 575px) {
  .product-info {
    width: 90em !important;
  }

  .product__icon-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .product__checkout_icon {
    margin: auto;
  }

  .product__view-details,
  .product__view-price {
    background-color: #dedcdc !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px !important;
  }

  .product__price-container {
    width: 80%;
    margin: auto;
  }

  .product__details-container {
    width: 500px !important;
  }

  .qrcode {
    display: none;
  }

  /* table, tr, td, th {
    border: 1px solid black;
    white-space: normal;
  } */

  table tr td:nth-child(1) {
    /* background-color: rgba(255, 255, 255, 0.45); */
    width: 30px;
  }

  table {
    table-layout: fixed;
    width: 100% !important;
  }

  th > div,
  td > div {
    max-width: 400px !important;
    min-width: 50px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .product__hidden-header {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .container {
    width: 10em !important;
    padding: 0px;
  }

  .product__details-container {
    width: 400px !important;
  }

  .price-text-default {
    font-style: italic;
    font-size: 15pt !important;
    font-weight: 0 !important;
  }
}

@media only screen and (max-width: 380px) {
  .product__details-container {
    width: 350px !important;
  }
}

@media only screen and (max-width: 300px) {
  .product__details-container {
    width: 300px !important;
  }

  .price-text {
    text-decoration-line: line-through;
    font-size: 15pt !important;
    font-weight: 500 !important;
  }

  .price-text-default {
    font-style: italic;
    font-size: 14pt !important;
    font-weight: 500 !important;
  }

  .product__icons {
    margin-left: 10px;
    margin-right: 10px;
  }
}
