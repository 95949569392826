@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Press+Start+2P&display=swap');

.header__username.header__username {
  color: #4e564e !important;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 17px;
}

.appbar * {
  color: #4e564e;
}

.spacer {
  flex: 1 1 auto;
}

.header {
  border-radius: 5px;
  background-color: #fff !important;
  /*background-image: url(../../images/logo.png);
  background-size: 170px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 2%; --- Use this to add logo from css instead of Header.js */
}

.logout *,
.login * {
  text-transform: capitalize;
  color: #4e564e !important;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 20px;
}

.product__user-menu * {
  text-transform: capitalize;
  font-size: 14px;
  color: #4e564e !important;
}

.company-logo {
  padding: 5px;
  height: 60px;
}

.account {
  fill: #09962a;
}

.header__main-menu {
  background-color: #fff;
  border-radius: 3px;
}

.header__menu-focus:hover {
  background-color: #28c4fc !important;
  font-family: 'Open Sans Condensed', sans-serif;
  opacity: 0.8;
}

.menu-icon {
  display: none !important;
}

@media only screen and (max-width: 500px) {
  .header__avatar.header__avatar {
    display: none;
  }

  .menu-icon {
    display: block !important;
  }

  .menu-expand-icon {
    display: none !important;
  }

  .header__username {
    display: none;
  }
}
