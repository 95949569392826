html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* background-color: #e2e2e2; */
  background-color: #dfdcdc;
  width: 100%;
}

.app-content {
  margin-top: 5%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #28c4fc;
}

@media only screen and (max-width: 600px) {
  .app-content {
    margin-top: 56px;
  }
}

button:disabled,
button[disabled] {
  background-color: #cccccc !important;
}

button:disabled *,
button[disabled] * {
  color: rgba(0, 0, 0, 0.4);
}
