@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Press+Start+2P&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500&display=swap');

.list-item {
  background-color: #ff682c;
}

.checkout__tab-controller {
  flex-grow: 1;
  background-color: #142e3e !important;
}

.manage-sample-tabs-container {
  flex-grow: 1;
  background-color: #142e3e !important;
}

.manage-sample-tabs-full-screen.manage-sample-tabs-full-screen {
  display: block;
  border-radius: 5px;
  color: #28c4fc !important;
}

.manage-sample-tabs-mobile.manage-sample-tabs-mobile {
  display: none;
  border-radius: 5px;
  color: #28c4fc !important;
}

.checkout-pending {
  height: 600px;
  overflow: auto;
}

.manage-samples-content {
  width: 95%;
  margin: auto;
}

.checkout-list {
  margin-top: 70px;
  flex-grow: 2;
  max-width: 100%;
  padding: 20px;
}

.checkout__header-text.checkout__header-text {
  height: 50px;
  background-color: #f8f8f8;
  color: #4e564e !important;
  padding-left: 15px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 90%;
}

.checkout__header-text-product.checkout__header-text-product {
  height: 50px;
  background-color: #f8f8f8;
  color: #4e564e !important;
  padding-left: 15px;
  text-align: left;
  display: flex;
  align-items: center;
  float: right;
}

.header-text {
  font-family: 'Open Sans Condensed', sans-serif !important;
  text-align: center;
  background-color: #142e3e;
  color: #28c4fc;
}

@media only screen and (max-width: 900px) {
  .manage-sample-tabs-full-screen.manage-sample-tabs-full-screen {
    display: none;
  }
  .manage-sample-tabs-mobile.manage-sample-tabs-mobile {
    display: block;
    color: #28c4fc !important;
  }
  .checkout__tab-controller.checkout__tab-controller {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
  .admin__settings-row.admin__settings-row,
  .admin__settings-cell.admin__settings-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .admin__create-user-table-row.admin__create-user-table-row,
  .admin__create-user-table-divider.admin__create-user-table-divider,
  .admin__create-user-table-body.admin__create-user-table-body,
  .admin__create-user-table.admin__create-user-table {
    padding: 10px 0px 0px 0px;
  }
  /* .admin-edit-user-paper.admin-edit-user-paper {
    width:700px;
  } */
  .admin__edit-user-form.admin__edit-user-form {
    width: 400px;
  }
  .admin__add-icon {
    margin-bottom: 50px;
    color: #ff682c !important;
  }

  .manage-samples-content {
    width: 100%;
    display: block;
  }

  .checkout-list {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .admin__role-list {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .admin__location-list {
    margin-bottom: 10px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .checkout__tab-controller.checkout__tab-controller {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkout-list {
    margin-bottom: 10px;
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .checkout__tab-controller.checkout__tab-controller {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .manage-samples-content {
    width: 100%;
    display: block;
  }

  .checkout-list {
    margin-bottom: 10px;
    max-width: 100%;
  }
}
