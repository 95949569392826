.user__change-password-modal {
  background-color: #f8f8f8 !important;
  color: #ff682c !important;
  width: 200px;
}

.user__cp-btn {
  background-color: #f4f9e9 !important;
  color: rgba(9, 150, 42, 0.6) !important;
}

.user__account-container {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
}

.user__avatar-container {
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user__avatar {
  width: 200px !important;
  height: 200px !important;
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.user__username {
  text-transform: capitalize;
}

.user_username-label {
  font-size: 12px !important;
}

.user__account-info-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 40px;
}

.user_account-data {
  margin-top: 14px;
  background-color: #ffffff !important;
}

/* .user__user-data {
  padding: 20px;
} */

/* .user__btn-container {
  margin-right: auto;
  display: flex;
  flex-direction: row-reverse;
} */

/* .user__update-btn {
  background-color: rgba(9, 150, 42, 0.6) !important;
  color: #fff !important;
} */

.user__cp-container {
  display: flex;
  flex-direction: column;
}

.app-content {
  margin-top: 80px;
}

#user-info-table {
  background-color: #ffffff !important;
}

.inf-content {
  padding: 20px;
  background-color: #ffffff !important;
  border: 1px solid #dddddd;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.3);
  width: 80%;
}

.user__desc {
  padding-right: 50px !important;
}

.user__tr {
  border-collapse: collapse;
  border-bottom: #142e3e 1px solid !important;
}

.user__table-user-information {
  border-collapse: collapse;
}

.user__table {
  padding: 10px !important;
}

.text-primary {
  padding-left: 90px !important;
}

.user__info-container {
  display: flex;
  justify-content: center;
}

.user__container {
  margin-top: 10em;
  display: flex;
  justify-content: center;
}

.user__submit-button {
  background-color: #fcfcfc !important;
  color: green !important;
  margin-left: 50px !important;
}

.user__edit-button {
  color: #ff682c !important;
  background-color: #fcfcfc !important;
}

.user__button-row {
  border-style: none !important;
}

@media only screen and (max-width: 500px) {
  .user__avatar.user__avatar {
    display: none;
  }
  .user__username {
    font-size: 6px;
  }
}
