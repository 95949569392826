/* Shared */

hr {
  margin-top: 50px;
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}
.loginBtn:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}
.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png')
    6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}

a.login__google:visited,
a.login__google:link,
a.login__google:hover {
  text-decoration: none;
  color: #fff;
}

.signin__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.5rem;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  color: #4e564e;
  background-color: #dfdcdc;
}

.signin__google {
  padding: 20px;
}

.signin__local {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 0.5rem;
  flex-grow: 0.2;
  /* padding: 2rem; */
  padding-top: 0rem;
  background-color: rgba(223, 220, 220, 0.4);
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(255, 104, 44, 0.22) 0px 15px 12px;
}

.signin__username,
.signin__password {
  /* width: 80%;
  margin: auto !important;
  color: #4E564E !important; */
  font-size: 22px !important;
  padding: 0.05rem;
  border-radius: 5px;
  border: #4e564e 1.5px solid !important;
  background-color: #fff !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
  margin-top: 0px;
}

.signin__google-btn {
  display: flex;
  justify-content: center;
}

.sigin__local-btn {
  color: #ff682c !important;
  width: 200px;
  height: 55px;
  background-color: #f4f9e9 !important;
}

.signin_local-btn-container {
  margin: auto;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
  border-radius: 5px;
}

.signin__header-text {
  color: #4e564e !important;
  font-size: 40px !important;
  padding: 20px;
}

.signin__container-header {
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 20px;
  box-shadow:
    rgba(0, 0, 0, 0.07) 0px 1px 1px,
    rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px,
    rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.signin__labels {
  margin-left: 40px;
  margin-top: 30px;
}

.hide {
  display: none;
}

.instructions > svg {
  margin-right: 0.25rem;
}
