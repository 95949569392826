.lead-management__container {
  margin-top: 8em;
  justify-content: center;
}

.lead-management__table {
  background-color: #ffffff;
  border-radius: 8px;
}

.lead-management__avatar {
  width: 150px !important;
  height: 150px !important;
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.lead-management__td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.lead-management__word-break {
  word-break: break-all;
}

.lead-management__sort-header .lead-management__sort-button {
  visibility: hidden;
}

.lead-management__sort-header:hover .lead-management__sort-button {
  visibility: visible !important;
  color: gray;
}

.lead-management__desktop-lead-table {
  display: block !important;
}

.lead-management__desktop-lead-row {
  display: table-row !important;
}

.lead-management__mobile-lead-table {
  display: none !important;
}

.lead-management__mobile-lead-row {
  display: none !important;
}

.lead-management__mobile-modal {
  display: none !important;
}

.lead-management__desktop-modal {
  display: block !important;
}

.lead-management__dialog-content {
  width: 40vw;
  max-width: 500px;
}

@media only screen and (max-width: 1050px) {
  .lead-management__desktop-lead-table {
    display: none !important;
  }

  .lead-management__desktop-lead-row {
    display: none !important;
  }

  .lead-management__mobile-lead-table {
    display: block !important;
  }

  .lead-management__mobile-lead-row {
    display: block !important;
    margin: 10px auto;
  }

  .lead-management__secondary-text {
    color: #ff682c !important;
  }

  .lead-management__mobile-modal {
    display: block !important;
  }

  .lead-management__desktop-modal {
    display: none !important;
  }

  .lead-management__dialog-content {
    width: 70vw;
  }
}
