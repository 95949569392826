.contact-card__table {
  background-color: #ffffff;
  border-radius: 8px;
}

.contact-card__avatar {
  width: 150px !important;
  height: 150px !important;
  border: 2px solid rgba(0, 0, 0, 0.5);
  display: inline-block;
  vertical-align: middle;
  margin: 16px;
}

.contact-card__icon {
  vertical-align: middle;
}

.contact-card__header {
  padding: 16px;
  color: #28c4fc;
  background-color: #142e3e;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.contact-card__contact-info {
  display: inline-block;
  vertical-align: middle;
  margin: 16px;
  text-align: left;
}
