.checkouts__header-typography {
  float: left;
}

.checkouts__container-full {
  min-width: 100%;
  min-height: 500px;
  display: block;
}

.checkouts__container-mobile {
  display: none;
}

/* .link-container {
    padding:40px;
} */

.link {
  display: flex;
  align-items: center !important;
  font-size: 35px;
  text-decoration: none;
  color: #4e564e;
  padding: 20px;
}

.checkout__links {
  color: #4e564e;
}

.checkout__table-cell {
  display: flex;
  align-items: center;
}

.checkout__scroll-menu {
  background-color: #dcdcdc !important;
  color: #4e564e !important;
}


@media only screen and (max-width: 760px) {
  .checkout__links {
    word-wrap: normal !important;
    display: inline-block;
    vertical-align: middle;
  }

  .checkouts__list-sub-header {
    font-size: 15px !important;
    color: #4e564e;
  }

  .checkouts__container-full {
    min-width: 100%;
    min-height: 500px;
    display: none;
  }

  .checkouts__container-mobile {
    display: block;
  }

  .checkouts__container-mobile p {
    color: unset !important;
  }

  .checkouts__list-menu {
    margin-top: -10px !important;
    background-color: #142e3e !important;
    color: #28c4fc !important;
    border-radius: 3px !important;
    height: 90px;
  }
}
