.checkout__header-typography {
  float: left;
}

.header-text {
  color: #28c4fc;
  background-color: #142e3e;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.pending__container-full {
  min-width: 100%;
  min-height: 500px;
}

.pending__container-mobile {
  min-width: 100%;
  min-height: 500px;
  display: none;
}

.pending__table {
  overflow: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5;
  padding: 5px;
}

@media only screen and (max-width: 760px) {
  .pending__container-full {
    min-width: 100%;
    min-height: 500px;
    display: none;
  }

  .pending__container-mobile.pending__container-mobile {
    display: block;
  }

  .checkout__list-menu {
    margin-top: -10px !important;
    background-color: #142e3e !important;
    color: #28c4fc !important;
    border-radius: 3px !important;
  }

  .checkout__list-item-secondary {
    font-size: 10px !important;
    color: #ff682c !important;
  }

  .checkout__list-item-stylename {
    font-size: 10px;
    font-weight: bolder;
    color: #000 !important;
  }
}
