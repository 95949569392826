@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Press+Start+2P&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.spoke-header {
  text-align: center;
  background-color: #e7e7e7;
  padding: 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.spoke-container {
  margin: auto;
  margin-top: 25px;
  min-height: 3em;
  max-width: 35em;
}

.spoke-body {
  padding: 10px;
}

.spoke-checkbox {
  padding: 10px;
  margin-top: 15px !important;
}

.checkbox {
  color: #ff682c !important;
}

.instructions-card {
  display: flex;
  align-items: center;
}

.instructions {
  background-color: #ffffff !important;
  height: 50%;
}

.verify-upload-table:nth-child(even) {
  background-color: #c9c9c9c9;
}

p {
  color: #4e564e !important;
  font-family: 'Open Sans', sans-serif;
}
.verify-upload-table:nth-child(odd) {
  background-color: #f8f8f8;
}

.uploads__download-icon {
  color: #ff682c;
}

#header {
  background-color: #8c8c8c;
  font-size: 25px;
  text-decoration: underline;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 11em;
}

.btn.btn {
  background-color: #eeeeee !important;
  /* box-shadow: 0 1px 1px 1px #5e5e5e5e; */
  border-radius: 5px;
  width: 155px;
  float: right;
  margin: 15px;
  color: #ff682c;
}

.export-btn.export-btn {
  background-color: #eeeeee !important;
  /* box-shadow: 0 1px 1px 1px #5e5e5e5e; */
  border-radius: 5px;
  /* width: 220px; */
  float: right;
  margin: 15px;
  color: #ff682c !important;
}

.review-txt {
  margin-left: 10px;
  font-family: 'Open Sans', sans-serif;
  color: #4e564e;
}

.upload-helper-txt {
  color: #4e564e;
}

.table_container {
  margin-top: 30px;
}

#bg-paper {
  margin-top: 50px;
  background-color: #ffffff;
}

.upload-paper.upload-paper {
  margin-top: 10px;
  /* background-color:  #F4F9E9; */
}

.export-paper {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  /* background-color: #F4F9E9 !important; */
}

.export-form {
  margin: 30px;
}

.export-form-item {
  margin: 20px;
  width: 50%;
}

#export-form-helper {
  margin-bottom: 10px;
}

#dropzone {
  display: flex;
  justify-content: center;
  height: 85%;
  width: 95%;
  border-radius: 5px;
  border: 1px;
  border-style: dashed;
  border-color: #5e5e5e;
  color: #4151af;
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
}

.dropzone-paper.dropzone-paper {
  margin-top: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 100%;
  /* background-color:  #F8F8F8; */
}

.upload-btn-paper.upload-btn-paper {
  display: flex;
  align-items: center;
  margin-top: 37px;
  margin-bottom: 10px;
  width: 100%;
  /* background-color: #F4F9E9 !important; */
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
}

.import-instructions-paper.import-instructions-paper {
  margin-top: 10px;
  margin-bottom: 10px;
  /* background-color: #F4F9E9 !important; */
  color: #4e564e;
  font-family: 'Open Sans', sans-serif;
}

.export-instructions-paper {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #4e564e;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4em !important;
  height: 400px;
  /* background-color: #F4F9E9 !important; */
}

.btn-preview-paper {
  display: flex;
  justify-content: flex-end;
  /* background-color: #f4f9e9 !important; */
}

#export-instructions-card {
  margin-top: 10px;
  width: 50%;
  text-align: center;
}

.card-content {
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
}

.upload-title {
  margin-top: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 60px;
  background-color: #142e3e !important;
  font-family: 'Open Sans Condensed', sans-serif;
  color: #28c4fc !important;
}

.manual-upload-select {
  margin: 25px;
}

.upload-icon.upload-icon {
  background-color: #142e3e !important;
  color: #ff682c;
  margin-right: 20px;
  font-size: 60px;
}

.file-upload-instructions {
  text-decoration: underline;
  color: #4e564e;
  font-family: 'Open Sans Condensed', sans-serif;
}

h4 {
  color: #4e564e;
  font-family: 'Open Sans Condensed', sans-serif;
}

.alert-button {
  background-color: #eeeeee !important;
  color: #ff682c !important;
}

#alert-dialog-title {
  background-color: #142e3e !important;
  color: #28c4fc !important;
  border-radius: 2px;
  font-family: 'Open Sans', sans-serif;
}
