@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Press+Start+2P&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500&display=swap');

table tr td:nth-child(1) {
  /* background-color: rgba(255, 255, 255, 0.45); */
  width: 30px;
}

table {
  table-layout: fixed;
  width: 100% !important;
}

th > div,
td > div {
  max-width: 100% !important;
  min-width: 50px;
}
.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #142e3e;
  color: #fff !important;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.user-exists {
  font-size: 0.75rem;

  color: red !important;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.admin__create-user-table-d {
  padding-top: 10px;
}

.admin__create-user-google-text {
  width: 500px;
  padding: 10px 40px 10px 40px;
}

.user__change-password-modal {
  width: 100%;
}

.admin__users-container-mobile {
  display: none;
}

.admin__users-container-full {
  display: block;
}

.admin__grid-item {
  justify-content: center;
  padding-top: 50px !important;
}

.admin__settings-table-cell-container {
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.admin__settings-table-cell-container:nth-of-type(odd) {
  border-right: 1px solid rgb(207, 206, 207);
  width: calc(50% - 3px);
}

.admin__settings-table-cell-container:nth-of-type(even) {
  border-left: 1px solid rgb(207, 206, 207);
  margin-left: -1px;
}

.info-icon {
  color: #ff682c;
}

.admin__tab-controller {
  flex-grow: 1;
  background-color: #142e3e !important;
}

.admin__settings-row {
  background-color: #ffffff;
}

.admin__tabs.admin__tabs {
  font-family: 'Open Sans', sans-serif;
}

.admin__container {
  width: 100%;
  margin: auto;
}

.admin__users {
  height: 600px;
  overflow: auto;
}

.admin__edit-user {
  flex-grow: 8;
}

.admin___users-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.admin__edit-user-header {
  text-align: center;
}

.admin__user-list {
  margin-top: 70px;
  flex-grow: 2;
  max-width: 33%;
}

.admin__role-list {
  margin-top: 70px;
  flex-grow: 2;
  max-width: 33%;
}

.admin__location-list {
  margin-top: 70px;
  flex-grow: 2;
  max-width: 33%;
}

.admin__search-users {
  width: 80%;
}

.admin__edit-user-form {
  padding-top: 0px;
  padding-bottom: 20px;
  overflow: auto;
  width: 100% !important;
}

.admin__add-icon {
  color: #ff682c !important;
}

.admin__create-user-table-row,
.admin__create-user-table-divider,
.admin__create-user-table-body,
.admin__create-user-table {
  padding: 10px 20px;
}

table tbody tr td:first-child {
  font-style: italic;
}

table tbody tr td:last-child {
  font-weight: 600;
}

.admin__form-actions {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin: 0px 40px;
}

.admin__delete-user {
  background-color: rgba(255, 0, 0, 0.6) !important;
}

.user__pw-change-button {
  color: #ff682c !important;
  background-color: #eeeeee !important;
}

.user__header-text.user__header-text {
  height: 50px;
  background-color: #f8f8f8;
  color: #4e564e !important;
  padding-left: 15px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 93%;
}

.user__header-text-last.user__header-text-last {
  height: 50px;
  background-color: #f8f8f8;
  color: #4e564e !important;
  padding-left: 15px;
  text-align: left;
  display: flex;
  align-items: center;
  float: right;
}

.header-bg {
  background-color: #f8f8f8;
}

.admin__update-user {
  color: #ff682c !important;
}

.admin-edit-user-paper {
  width: 60%;
}

.Admin_create-user-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.admin__set-user-access {
  width: 80%;
  min-width: 180px;
}

.admin__edit-user-dropdowns {
  width: 30%;
  min-width: 180px;
}

.Admin__create-user-header {
  text-align: center;
  background-color: #142e3e !important;
  color: #28c4fc;
}

.admin__bulletin-buttons {
  background-color: #eeeeee !important;
  color: #ff682c !important;
}

.Admin__create-user * {
  color: rgba(9, 150, 42, 0.7);
}

.admin__announcements-container {
  height: 450px;
  overflow: auto;
}

.admin__announcements-actions {
  display: flex;
  flex-direction: row-reverse;
}

.admin__announcements-create {
  margin-right: 20px !important;
  margin-bottom: 10px !important;
  background-color: #eeeeee !important;
  color: rgba(9, 150, 42, 0.7) !important;
}

.admin__announcement-avatar {
  opacity: 0;
}

.admin__input-announcement {
  padding-bottom: 10px !important;
  width: 100%;
}

.admin__date-picker {
  display: flex;
  justify-content: space-around;
}

.admin__delete-announcement {
  background-color: rgba(255, 0, 0, 0.8) !important;
}

.admin__create-bulletin-container {
  display: flex;
  justify-content: flex-end;
}

.admin__bulletin-create {
  color: rgba(9, 150, 42, 0.7) !important;
}

.admin__bulletin-image-uploader {
  display: none;
}

.admin__bulletin-image-preview {
  padding: 15px;
}

.admin__bulletin-image-preview-gridList {
  width: 100%;
  transform: 'translateZ(0)';
}

.admin__image-upload.admin__image-upload-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.admin__image-upload__preview {
  width: 34rem;
  height: 18rem;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

.admin__image-upload__preview img {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  object-fit: cover;
}

.admin__announcement-list-item.active::after {
  content: '';
  position: absolute;
  height: 45px;
  top: 13px;
  border-left: rgba(9, 150, 42, 0.6) solid;
}

.admin__announcement-list-item.inactive::after {
  content: '';
  position: absolute;
  height: 45px;
  top: 13px;
  border-left: rgba(255, 0, 0, 0.6) solid;
}

.admin__announcement-list-item.future::after {
  content: '';
  position: absolute;
  height: 45px;
  top: 13px;
  border-left: rgba(255, 165, 0, 0.6) solid;
}

.admin__announcement-info-container {
  display: flex;
  justify-content: flex-end;
}

.admin__info-expired {
  border-bottom: rgba(255, 0, 0, 0.6) solid;
  padding: 5px;
}

.admin__info-active {
  border-bottom: rgba(9, 150, 42, 0.6) solid;
  padding: 5px;
}

.admin__info-future {
  border-bottom: rgba(255, 165, 0, 0.6) solid;
  padding: 5px;
}

.admin___popover-container {
  display: flex;
  padding: 10px;
}

.admin___popover-container * {
  margin-left: 8px !important;
}

.admin__cards-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 550px;
  overflow: auto;
}

.admin__cards-container div {
  margin-left: 10px;
  margin-bottom: 10px;
}

.admin__bulletin-card {
  max-width: 345px;
  min-width: 250px;
}

.admin__card-actions {
  display: flex;
  justify-content: flex-end;
}

.admin__card-action-delete {
  background-color: rgba(255, 0, 0, 0.8) !important;
}

.admin__card-image {
  -webkit-filter: grayscale(100%);
  filter: grayscale(35%);
}

/* #alert-dialog-title {
  font-family: 'Open Sans Condensed', sans-serif;
  color:#28C4FC !important;
} */

.admin__tax-header {
  height: 96px;
}

.admin__tax-content {
  background-color: #ffffff !important;
  margin: 10px auto;
  width: 95%;
}

.admin__tax-content-label {
  margin: 30px auto;
  width: 90%;
  font-family: 'Open Sans Condensed', sans-serif;
  color: #ff682c;
}

.admin__tax-actions {
  display: flex;
  justify-content: flex-end;
}

.admin__tax-update-btn {
  background-color: #eeeeee !important;
  color: #ff692c !important;
}

.admin__select-role-label {
  font-family: 'Open Sans Condensed', sans-serif;
}

.admin__margin-role-select {
  width: 100%;
  margin: 10px auto;
}

.create-user__subtitle {
  color: rgba(0, 0, 0, 0.7);
}

.create-user__type {
  display: flex !important;
  flex-direction: row !important;
}

.create-user__radio {
  color: rgba(9, 150, 42, 0.7) !important;
}

.admin__users-list {
  overflow: auto;
  width: 100%;
}

.admin__update-customer-logo {
  margin-bottom: -80px;
  margin-top: 150px;
}

.admin__show-manufacturer {
  margin-bottom: 100px;
  width: 40%;
  margin-top: 150px;
}

.settings__container {
  margin: auto;
  max-width: 95%;
}

.header-text {
  font-family: 'Open Sans Condensed', sans-serif !important;
  text-align: center;
  background-color: #142e3e;
  color: #28c4fc;
}

.admin__upload-logo {
  padding: 20px;
  display: flex;
  width: 100%;
}

.admin__search-logo-btn {
  margin: 30px 0px 0px 20px !important;
}

.admin__upload-logo-btn {
  margin: 30px 0px 0px 20px !important;
  background-color: #4caf50 !important;
  color: #ffffff !important;
}

.upload-logo-text {
  margin-right: 0px;
}

.admin__update-link-logo {
  margin-bottom: 10px;
  max-width: 50%;
}

.admin__logo-link-btn {
  background-color: #4caf50 !important;
  color: #ffffff !important;
  margin: 10px auto auto !important;
}

.admin__full-screen-tabs.admin__full-screen-tabs {
  display: block;
  border-radius: 5px;
  color: #28c4fc !important;
}

.admin__mobile-tabs.admin__mobile-tabs {
  display: none !important;
}

/* .admin__settings-cell {
  display: flex; 
  justify-content: center; 
  width: 100%;
} */

.admin__product-settings.admin__product-settings {
  text-align: center;
  margin: 40px 0px 0px;
  color: #4e564e;
}

.admin__user-header-button {
  color: #ff682c !important;
  font-size: 20px !important;
  float: right !important;
  border: 2px #28c4fc solid !important;
}

@media only screen and (max-width: 900px) {
  .admin__user-header-button {
    color: #ff682c !important;
    font-size: 20px !important;
    float: right !important;
    border: 2px #28c4fc solid !important;
  }

  .admin__full-screen-tabs.admin__full-screen-tabs {
    display: none;
  }
  .admin__mobile-tabs.admin__mobile-tabs {
    display: block !important;
    color: #28c4fc !important;
  }
  .admin__tab-controller.admin__tab-controller {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .admin__settings-row.admin__settings-row,
  .admin__settings-cell.admin__settings-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .admin__create-user-table-row.admin__create-user-table-row,
  .admin__create-user-table-divider.admin__create-user-table-divider,
  .admin__create-user-table-body.admin__create-user-table-body,
  .admin__create-user-table.admin__create-user-table {
    padding: 10px 20px !important;
  }
  /* .admin-edit-user-paper.admin-edit-user-paper {
    width:700px;
  } */
  /* .admin__edit-user-form.admin__edit-user-form { 
  padding-bottom: 20px;
  max-height: 550px;
  overflow: auto;
  width: 100% !important;
  }   */
  .admin__add-icon {
    margin-bottom: 50px;
    color: #ff682c !important;
  }

  .admin___users-content {
    width: 100%;
    display: block;
  }

  .admin__user-list {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .admin__role-list {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .admin__location-list {
    margin-bottom: 10px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .admin__users-container-mobile {
    display: block;
  }

  .admin__users-container-full {
    display: none;
  }

  .header-bg {
    display: none !important;
  }

  .admin__settings-table-cell-container:nth-of-type(n) {
    border: 0px;
    width: 100%;
    margin: 0px;
    border-bottom: 1px solid rgb(207, 206, 207);
  }

  .admin__full-screen-tabs.admin__full-screen-tabs {
    display: none;
  }
  .admin__mobile-tabs.admin__mobile-tabs {
    display: block;
    color: #28c4fc !important;
  }
  .admin__tab-controller.admin__tab-controller {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .admin__add-icon {
    margin-bottom: 50px;
    color: #ff682c !important;
  }
  .admin___users-content {
    width: 100%;
    display: block;
  }
  .admin__user-list {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .admin__role-list {
    margin-bottom: 10px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .admin__full-screen-tabs.admin__full-screen-tabs {
    display: none;
  }
  .admin__mobile-tabs.admin__mobile-tabs {
    display: block;
    color: #28c4fc !important;
  }
  .admin__tab-controller.admin__tab-controller {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .admin__create-user-table-row.admin__create-user-table-row,
  .admin__create-user-table-divider.admin__create-user-table-divider,
  .admin__create-user-table-body.admin__create-user-table-body,
  .admin__create-user-table.admin__create-user-table {
    width: 100% !important;
  }
  /* .admin-edit-user-paper.admin-edit-user-paper {
    width:700px;
  } */
  .admin__edit-user-form.admin__edit-user-form {
    margin: auto;
    float: right;
  }
  .admin__add-icon {
    margin-bottom: 50px;
    color: #ff682c !important;
  }
  .admin___users-content {
    width: 100%;
    display: block;
  }

  .admin__user-list {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .admin__role-list {
    margin-bottom: 10px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  /* .admin__full-screen-tabs.admin__full-screen-tabs {
    display: none;
  } */
  .admin__mobile-tabs.admin__mobile-tabs {
    display: block;
    color: #28c4fc !important;
  }
  .admin__tab-controller.admin__tab-controller {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .admin__update-customer-logo {
    display: none;
  } */
  .admin__create-user-table-row.admin__create-user-table-row,
  .admin__create-user-table-divider.admin__create-user-table-divider,
  .admin__create-user-table-body.admin__create-user-table-body,
  .admin__create-user-table.admin__create-user-table {
    padding: 10px 0px 0px 0px;
  }
  /* .admin-edit-user-paper.admin-edit-user-paper {
    width:700px;
  } */
  .admin__edit-user-form.admin__edit-user-form {
    width: 280px;
  }
  .admin___users-content {
    width: 100%;
    display: block;
  }
  .admin__user-list {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .admin__role-list {
    margin-bottom: 10px;
    max-width: 100%;
  }
}
