.mock-text {
  height: 16px;
  width: 100px;
  background: rgba(0, 0, 0, 0.25);
}

.mock-product-info table tbody tr td {
  padding: 10px 20px 10px 20px;
}

.loading {
  display: flex !important;
  justify-content: center !important;
  font-size: 50px;
  color: #ff682c !important;
}

.mock-product-container {
  width: 20em;
}

@media only screen and (max-width: 375px) {
  .loading {
    display: flex !important;
    justify-content: center !important;
    font-size: 30px;
    color: #ff682c !important;
  }
}
