@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.reg__header-text {
  color: #4e564e !important;
  font-size: 60px !important;
  padding: 20px;
}

.reg__container-header {
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 20px;
  margin-top: -48px !important;
  margin-left: -48px !important;
  margin-right: -48px !important;
  box-shadow:
    rgba(0, 0, 0, 0.07) 0px 1px 1px,
    rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px,
    rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  padding: 1rem 0.5rem;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  color: #4e564e;
  background-color: #dfdcdc;
}

.register__captcha {
  margin: auto;
  margin-top: 50px;
}

.register__section {
  width: 100%;
  max-width: 420px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3rem;
  /* background-color: rgba(223, 220, 220, 0.4); */
  background-color: #dedcdc;
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(255, 104, 44, 0.22) 0px 15px 12px;
  /* box-shadow: 2px 2px 5px 5px #FF682C ; */
  margin-top: 50px;
  border-radius: 5px;
}

.register__form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}

.register__text {
  margin-bottom: 5rem;
  text-align: center;
  /* text-decoration: underline; */
}

.register__signin-link,
.register__signin-link:visited {
  color: #4e564e;
}

.register__text-field[type='text'],
.register__text-field[type='password'],
.register__signup-button,
.register__text-field {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem !important;
  border: #4e564e solid 2px;
  background-color: #fff;
  width: 300px !important;
}

.register__text-field {
  width: 85%;
  margin: auto;
}

.register__text-field_span {
  margin: auto;
}

.register__signup-button {
  margin: auto;
  margin-top: 3rem;
  width: 40%;
}

.register__label {
  margin-left: 12%;
  margin-top: 1rem;
}

.register__signup-button {
  padding: 0.5rem;
  background-color: #48a02d;
}

.register__signup-button:active {
  background: rgb(180, 180, 180);
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
  -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
  box-shadow: inset 0px 0px 5px #c1c1c1;
  outline: none;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #142e3e !important ;
  color: #fff !important;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.user-exists {
  font-size: 0.75rem;

  color: red !important;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.register__captcha-branding {
  font-size: small;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.user-exists {
  margin-right: 0.25 rem;
}

.offscreen {
  display: none;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.signin__container-header {
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 20px;
  box-shadow:
    rgba(0, 0, 0, 0.07) 0px 1px 1px,
    rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px,
    rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
