@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Press+Start+2P&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,800&display=swap');

.grid-item.grid-item {
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 10px;
}

.mnd__box-paper {
  width: 99%;
  height: 260px;
}

.mnd__box-paper-disclaimer {
  width: 99%;
  height: 260px;
}

.mnd__text-area {
  display: block;
  margin: 40px auto 20px !important;
  width: 100%;
}

.MND__disclaimer-bg {
  background-color: #ffffff;
  margin-top: 0px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.MND__disclaimer-grids {
  padding-top: 40px;
  padding-left: 40px;
}
.cost-adjustment-paper.cost-adjustment-paper {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  background-color: #f8f8f8 !important;
  margin-bottom: 50px;
  color: #4e564e !important;
  /* border-radius: 10px; */
  /* width: 95%; */
}

.mnd__header-text {
  font-family: 'Open Sans Condensed', sans-serif !important;
  color: #28c4fc;
}

.mnd__header {
  background-color: #142e3e;
  text-align: center;
}

.mnd__disclaimer-paper {
  margin-top: 60px;
}

.mnd__tax-text-area {
  width: 300px;
}

.mnd__tax-switch {
  display: flex;
  align-items: center;
  padding: 5px 0px 0px;
}

.mnd__product-status-switch {
  display: flex;
  align-items: center;
}

.mnd__info-table-cell {
  width: 210px;
}

.mnd__tax-table-cell {
  width: 170px;
}

.mnd__mat-margin-sel {
  background-color: #f8f8f8 !important;
  margin: auto;
  color: #4e564e !important;
  text-align: center;
}

.mnd__table-cell.mnd__table-cell {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: large;
  color: #4e564e;
  width: 100% !important;
}

.promo-switch.promo-switch {
  display: flex;
  justify-content: center;
}

.mnd__info-button {
  margin: 50px !important;
}

/* @media only screen and (max-width: 1900px) {
  .mnd__table-row.mnd__table-row, .mnd__table-cell.mnd__table-cell, .mnd__info-table-cell.mnd__info-table-cell, .mnd__tax-table-cell.mnd__tax-table-cell   { 
    display: block; 
    width:100%;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: large;
    color: #4E564E;
  } 
  .admin__margin-role-select {
    width:80%;
  } 
  .admin__select-role-label {
    margin-left: -40px;
    word-wrap: break-word;
  }  

  .mnd__box-paper {
    width: 97%;
    height: 100%;
    }

    .mnd__box-paper-disclaimer {
      width: 97%;
      height: 100%;
      }
} */

@media only screen and (max-width: 1420px) {
  .mnd__tax-label {
    font-size: xx-small;
  }

  .mnd__tax-text-area {
    width: 155px;
  }

  .mnd__tax-table-cell {
    width: 155px;
  }

  .mnd__info-table-cell {
    width: 155px;
  }

  .promo-switch.promo-switch {
    display: flex;
    justify-content: center;
    font-size: xx-small !important;
  }
}

@media only screen and (max-width: 1055px) {
  .mnd__table-row.mnd__table-row,
  .mnd__table-cell.mnd__table-cell,
  .mnd__info-table-cell.mnd__info-table-cell,
  .mnd__tax-table-cell.mnd__tax-table-cell {
    display: block;
    width: 100%;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: large;
    color: #4e564e;
  }
  .admin__margin-role-select {
    width: 80%;
  }
  .admin__select-role-label {
    margin-left: -40px;
    word-wrap: break-word;
  }

  .mnd__box-paper {
    width: 100%;
    height: 300px;
  }

  .mnd__box-paper-disclaimer {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .mnd__table-row.mnd__table-row,
  .mnd__table-cell.mnd__table-cell,
  .mnd__info-table-cell.mnd__info-table-cell,
  .mnd__tax-table-cell.mnd__tax-table-cell {
    display: block;
    width: 100%;
  }
  .admin__select-role-label {
    margin-left: -10px;
    word-wrap: break-word;
  }

  .mnd__box-paper {
    width: 100%;
    height: 300px;
  }

  .grid-item.grid-item {
    margin-bottom: 70px;
  }

  .mnd__box-paper-disclaimer {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 376px) {
  .mnd__table-row.mnd__table-row,
  .mnd__table-cell.mnd__table-cell,
  .mnd__info-table-cell.mnd__info-table-cell,
  .mnd__tax-table-cell.mnd__tax-table-cell {
    display: block;
    width: 100%;
  }
  .admin__select-role-label {
    margin-left: -10px;
    word-wrap: break-word;
  }

  .mnd__box-paper {
    width: 100%;
    height: 350px;
  }
}

@media only screen and (max-width: 350px) {
  .mnd__table-row.mnd__table-row,
  .mnd__table-cell.mnd__table-cell,
  .mnd__info-table-cell.mnd__info-table-cell,
  .mnd__tax-table-cell.mnd__tax-table-cell {
    display: block;
    width: 100%;
    margin-left: -10px;
  }
  .admin__select-role-label {
    margin-left: -10px;
    word-wrap: break-word;
  }

  .mnd__box-paper {
    width: 100%;
    height: 300px;
  }
}
