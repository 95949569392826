.rowMobile-container {
  background-color: rgba(20, 46, 62, 0.5) !important;
  border-top: 0.5px solid !important;
  border-bottom: 0.5px solid !important;
  margin: 10px;
}

.rowMobile-container-sublist {
  background-color: rgba(220, 220, 220) !important;
  min-height: 70px !important;
  margin: 10px;
  border: 1px solid #000;
  border-radius: 5px !important;
}

.rowMobilelist-menu {
  /* border-top: .5px solid !important; */
}

.rowMobile-sub-menu {
  background-color: rgba(20, 46, 62, 0.5) !important;
  min-height: 70px !important;
  border-radius: 2px !important;
}

.rowMobile-promotion-sub-menu {
  background-color: rgba(0, 154, 0, 0.898) !important;
  min-height: 70px !important;
  border-radius: 2px !important;
}

@media only screen and (max-width: 700px) {
}
