@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Press+Start+2P&display=swap');

.data-cell-name-header.data-cell-name-header {
  text-align: left;
  width: 400px;
  font-weight: 900;
  font-size: 20px;
}

.data-cell-date {
  text-align: left;
  max-width: 400px;
}

.data-cell-files {
  width: 400px !important;
  overflow-wrap: anywhere;
}

.data-cell-delete {
  max-width: 400px;
}

.stats__mvp-data-download-container {
  background-color: #142e3e !important;
}

.full-screen-tabs.full-screen-tabs {
  display: block;
  color: #28c4fc !important;
  background-color: #142e3e;
  margin-top: 10px;
}

.mobile-tabs.mobile-tabs {
  display: none;
}

.stats__mvp-data-download-button.stats__mvp-data-download-button {
  color: #ff682c !important;
  background-color: #eeeeee !important;
}

.home__announcement-header.home__announcement-header {
  color: #28c4fc !important;
}

.home__content {
  margin-top: 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.home__papers.home__papers {
  background-color: #fff !important;
  border: solid 1px #28c4fc;
  /* box-shadow: 7px 5px 10px 1px #28c4fc; */
}

.home-grid {
  display: flex;
  flex-direction: row;
}

.home__bulletin {
  margin: 10px;
}

.home__activity {
  margin: 5px;
}

.home__filehistory {
  margin-top: 10px;
}

.home__announcements {
  margin-top: 10px;
}

.home__sales {
  margin-top: 10px;
}

.Lookback__title {
  font-size: 25px;
  margin: 20px;
}

.home__appbar {
  background-color: #142e3e !important;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 10px;
}

.home__announcement-header {
  color: #28c4fc !important;
  margin-bottom: 10px !important;
  font-family: 'Open Sans Condensed', sans-serif !important;
}

.home__announcement-container {
  max-height: 300px;
  overflow: auto;
}

.home__announcement-card {
  margin: 10px;
}

.home__announcement-box {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.home__announcement-card:hover {
  box-shadow: 0 15px 20px rgba(10, 0, 10, 0.3);
  /* -webkit-filter: brightness(110%); */
}

.home__simple-pie-title {
  padding: 10px;
  text-align: center;
}

.home__view-more-stats {
  display: flex;
  justify-content: flex-end;
}

.annoucement-header .MuiCardHeader-subheader {
  font-size: 12px;
}

@media only screen and (max-width: 1700px) {
  .data-cell-name-header.data-cell-name-header {
    text-align: left;
    width: 200px !important;
    font-weight: 900;
    font-size: 20px;
  }

  .data-cell-date {
    text-align: left;
    max-width: 400px;
  }

  .data-cell-files {
    max-width: 200px;
    overflow-wrap: anywhere;
  }

  .data-cell-delete {
    max-width: 400px;
  }
}

@media only screen and (max-width: 1100px) {
  .file-history-grid.file-history-grid {
    min-width: 100%;
  }

  .full-screen-tabs.full-screen-tabs {
    display: none;
  }

  .mobile-tabs.mobile-tabs {
    display: block;
    color: #28c4fc;
    background-color: #142e3e;
  }

  .home__content {
    display: block;
  }

  .home-grid.home-grid {
    display: block;
    flex-direction: column;
    justify-content: center;
  }

  .home__bulletin {
    width: 200%;
  }

  .home__sales {
    width: 200%;
  }

  .home__announcements {
    width: 200%;
  }

  .home__filehistory {
    width: 200%;
  }
}

@media only screen and (max-width: 700px) {
  .full-screen-tabs.full-screen-tabs {
    display: none;
  }

  .mobile-tabs.mobile-tabs {
    display: block;
    color: #28c4fc;
    background-color: #142e3e;
  }

  /* .file-history-grid {
    display: none;
  } */

  .home__content {
    display: flex;
    align-content: center;
  }

  .home-grid.home-grid {
    display: block;
    flex-direction: column;
    justify-content: center;
  }

  .home__bulletin {
    width: 200%;
  }

  .home__sales {
    width: 200%;
  }

  .home__announcements {
    width: 200%;
  }

  .home__filehistory {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .files-header-row {
    /* visibility: hidden; */
  }

  .data-cell-date {
    text-align: left;
    max-width: 400px;
  }

  .data-cell-files {
    max-width: 400px;
    overflow-wrap: anywhere;
  }

  .data-cell-delete {
    max-width: 400px;
  }
}

@media only screen and (max-width: 300px) {
  .files-header-row {
    visibility: hidden;
  }

  .data-cell-date {
    text-align: left;
    max-width: 400px;
  }

  .data-cell-files {
    max-width: 100px;
    overflow-wrap: anywhere;
  }

  .data-cell-delete {
    max-width: 400px;
  }
}
