.row__checkbox {
  color: #ff682c !important;
}

.product-detail-table:nth-child(even) {
  background-color: #c9c9c9;
}

.product-detail-table:nth-child(odd) {
  background-color: #fff;
}

.product-view-button {
  background-color: #eeeeee !important;
  color: #ff682c !important;
}

.text-field.text-field {
  width: 50px;
  height: 15px;
}

.table-header.table-header {
  display: block;
  table-layout: fixed;
  width: 500px;
}

.table-head {
  background-color: #28c4fc !important;
  border: 2px solid black !important;
  border-left: none !important;
  border-right: none !important;
}

.select-all-checkbox {
  background-color: #28c4fc !important;
  border: 2px solid black !important;
  border-left: none !important;
  border-right: none !important;
}

/* .row__table-row.row__table-row {
	background-color: #f4f9e9 !important;
} */

.row__edit-btn {
  background-color: #eeeeee !important;
  color: #ff682c !important;
  margin: 10px !important;
  float: left;
}

.row__update-button {
  color: #09962a !important;
  padding-left: 20px;
  float: right;
}

.row__delete-btn {
  background-color: #eeeeee !important;
  color: #ff682c !important;
  margin: 10px !important;
  float: left;
}

@media only screen and (max-width: 1230px) {
  .table-head.table-head {
    display: none;
  }
  .row__table-row.row__table-row,
  .row__table-cell.row__table-cell {
    display: block;
    width: 100%;
    background-color: #f4f9e9 !important;
  }
  .select-all-checkbox:nth-of-type(1):before {
    content: 'Select All: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(1):before {
    content: 'Select This Product: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(2):before {
    content: 'More Details: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(3):before {
    content: 'Manufacturer: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(4):before {
    content: 'Distributor: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(5):before {
    content: 'Private Style Name: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(6):before {
    content: 'Style Name: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(7):before {
    content: 'Style Number: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(8):before {
    content: 'Material Class: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(9):before {
    content: 'Material Sub Class: ';
  }
}

@media only screen and (max-width: 450px) {
  .table-head.table-head {
    display: none;
  }
  .row__table-row.row__table-row,
  .row__table-cell.row__table-cell {
    display: block;
    width: 100%;
  }
  .select-all-checkbox:nth-of-type(1):before {
    content: 'Select All: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(1):before {
    content: 'Select This Product: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(2):before {
    content: 'More Details: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(3):before {
    content: 'Manufacturer: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(4):before {
    content: 'Distributor: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(5):before {
    content: 'Private Style Name: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(6):before {
    content: 'Style Name: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(7):before {
    content: 'Style Number: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(8):before {
    content: 'Material Class: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(9):before {
    content: 'Material Sub Class: ';
  }
}

@media only screen and (max-width: 375px) {
  .table-head.table-head {
    display: none;
  }
  .row__table-row.row__table-row,
  .row__table-cell.row__table-cell {
    display: block;
    width: 100%;
  }
  .select-all-checkbox:nth-of-type(1):before {
    content: 'Select All: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(1):before {
    content: 'Select This Product: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(2):before {
    content: 'More Details: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(3):before {
    content: 'Manufacturer: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(4):before {
    content: 'Distributor: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(5):before {
    content: 'Private Style Name: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(6):before {
    content: 'Style Name: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(7):before {
    content: 'Style Number: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(8):before {
    content: 'Material Class: ';
  }
  .row__table-cell.row__table-cell:nth-of-type(9):before {
    content: 'Material Sub Class: ';
  }
}
