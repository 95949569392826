@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Press+Start+2P&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500&display=swap');

.about__support-text {
  font-size: 20px;
  color: #4e564e !important;
}

.about__back-to-top-footer {
  color: #28c4fc !important;
}

.about__version {
  color: #4e564e !important;
}

.about__header-div {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-top: 100px;
}

.about__header {
  background-color: #142e3e;
  border-radius: 3px;
  color: #28c4fc !important;
  font-family: 'Open Sans Condensed', sans-serif !important;
}

.about__constact-header {
  background-color: #142e3e;
  border-radius: 3px;
  color: #28c4fc !important;
  font-family: 'Open Sans Condensed', sans-serif !important;
}

.card {
  background-color: #a2abb7 !important;
}

.support-hours {
  font-style: italic;
  font-size: large;
  color: #4e564e !important;
}

.container {
  margin: auto;
  width: 100% !important;
}

.message-text-field {
  width: 75%;
}

.text-field.text-field {
  width: 34%;
  margin-right: 3% !important;
  margin-left: 3% !important;
  margin-bottom: 7% !important;
  padding: 0.05em;
}

.about-container {
  text-align: center;
  margin-bottom: 5%;
  color: #4e564e;
  width: 100%;
}

.video-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-top: 100px;
  width: 90%;
}

.about-tab-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #142e3e;
  border-radius: 5px;
}

.about-tab-container-mobile {
  display: none;
}

.tab-links {
  margin-left: 10px;
  margin-right: 10px;
  color: #28c4fc !important;
}

.contact-form.contact-form {
  margin: 0 auto;
  padding-bottom: 10px;
  max-width: 35em;
  margin-bottom: 50px;
  background-color: #ffffff;
}

.changelog-header {
  text-align: center !important;
}

.version-text.version-text {
  margin-left: 10px;
}

.about-version-grid {
  display: flex;
  align-items: center;
}

.version-text {
  color: #4f4e4e;
}

.contact-paper {
  max-width: 50%;
  margin-bottom: 100px;
}

.about-paper {
  max-width: 50%;
  margin-bottom: 100px;
}

.video-popup {
  min-width: 100%;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.video-grid {
  display: flex;
  justify-content: center;
}

.video-grid-paper.video-grid-paper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  margin-bottom: 70px;
  background-color: #ffffff !important;
}

.menu-items {
  text-align: center;
  color: #6a6a6a;
}

.tab-links {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: medium;
  font: sans-serif;
  color: #4f4e4e;
}

.to-top-links.to-top-links {
  margin: auto;
  text-decoration: none;
  font-size: large;
  font: sans-serif;
  color: #ff682c !important;
  font-weight: bold;
}

.links {
  text-decoration: none;
  font-weight: bold;
  color: #28c4fc;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video-grid-paper p {
  text-align: center;
}

.contact-button.contact-button {
  margin-bottom: 10px;
  margin-top: 70px;
  background-color: #eeeeee;
  color: #ff682c !important;
  width: 30%;
  height: 50px;
  border-radius: 5px;
}

@media only screen and (max-width: 1024px) {
  .about-tab-container.about-tab-container {
    display: none;
  }
  .about-tab-container-mobile.about-tab-container-mobile {
    display: block;
  }
  .about-tab-paper.about-tab-paper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #28c4fc;
  }

  .message-text-field {
    width: 100%;
  }

  .text-field {
    width: 100%;
  }
}
