@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&display=swap');

.fh__container-full {
  display: block;
}

.fh__container-mobile {
  display: none;
}

.fh__list-menu {
  margin-top: -10px !important;
  background-color: #142e3e !important;
  color: #28c4fc !important;
  border-radius: 3px !important;
  word-wrap: break-word;
}

.fh_list-sub-header {
  font-size: 40px !important;
  color: #4e564e;
}

.table-cell.table-cell {
  font-weight: 500;
  width: 12%;
}

.header-cell.header-cell {
  font-weight: 900;
  font-size: 20px;
  width: 12%;
}

.link {
  color: #333;
  font-size: 15px !important;
}

.files-by-partner-header {
  background-color: #142e3e;
  color: #28c4fc;
}

.files-by-partner-button {
  color: #ff682c !important;
  background-color: #eeeeee !important;
  font-size: 0.6vw !important;
}

@media only screen and (max-width: 1400px) {
  .container {
    display: block;
    align-items: center;
  }

  .header-cell.header-cell {
    font-weight: 900;
    font-size: 10px;
    width: 10px;
  }

  .files-by-partner-header {
    background-color: #142e3e;
    color: #28c4fc;
  }
}

@media only screen and (max-width: 760px) {
  .fh__container-full {
    display: none;
  }

  .fh__container-mobile {
    display: block;
  }
}
