.title {
  font-size: 14px;
  /* color: rgba(0, 0, 0, 0.7); */
  font-weight: 600;
}

#alert-dialog-title {
  background-color: #ffffff !important;
}

.update-product-button {
  background-color: #eeeeee !important;
  color: #ff682c !important;
}

.product__update {
  min-width: 700px !important;
}

.product__info-card {
  /* min-width: 250px; */
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.product__info {
  float: right;
  margin-left: 20px !important;
  color: rgba(9, 150, 42, 0.7) !important;
}

.product-editor {
  background-color: #ffffff !important;
}

.product_update-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: inherit !important;
}

.product_input-margin {
  margin-bottom: 10px;
  width: 85% !important;
}

.product__offer-dates {
  margin-top: 10px !important;
}

@media only screen and (max-width: 600px) {
  .product_update-content {
    display: flex;
    flex-direction: column;
  }
}

.product__size-control {
  min-width: 120px;
  width: 100%;
}

.product__update-margin-info {
  display: flex;
  flex-direction: row;
}

.product-update___popover-container {
  display: flex;
  flex-direction: column;
}

.product-update__margin-reset-btn {
  margin-top: 5px !important;
  color: rgba(255, 0, 0, 0.6) !important;
}

.product-update__edited-user-header {
  color: rgba(0, 0, 0, 0.6);
}
